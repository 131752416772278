<template>
  <div />
</template>

<script>
//import ImageSiteConcurseiroLogo from "@/assets/images/concurseiro-logo.png";
import imageSiteFormaEffect01 from "@/assets/images/form-effect-01.png";
//import ImageSiteLinhaMestreIcon from "@/assets/images/linha-mestre-icon.png";
export default {
  name: "HomeExternoConvite",
  data: function() {
    return {
      //ImageSiteConcurseiroLogo,
      imageSiteFormaEffect01,
      //ImageSiteLinhaMestreIcon,
    };
  },
  mounted() {},
};
</script>

<style></style>
